import moment from 'moment'

// Pass in single values for day, month and year and get back a concatenated string. If any of these values are undefined, return blank. Used to format DOBs from single select fields.
// 4 March 2000
export const formatHumanDateFromSingleValues = (day, month, year) => {
    if (day === '' || month === '' || year === '') {
        return ''
    } else {
        return `${day} ${month} ${year}`
    }
}

// To send to GTM data layer when an appointment is booked
// 15032023-12:00:00 (DDMMYYYY-HH:mm:ss)
export const formatGtmAppointmentDateTime = (dateTime) => {
    const date = moment(dateTime).format('DDMMYYYY-HH:mm:ss')

    return date
}

// To show within user confirmations and to send through email so the appointment date is readable
// Wednesday, 09 February 2022, 10:45
export const formatHumanAppointmentDateTime = (dateTime) => {
    const date = moment(dateTime).format('LLLL')

    return date
}

// Used to format DOB from Office Line from separate values
// 2000-05-01 (YYYY-MM-DD)
export const formatOfficeLineDob = (day, month, year) => {
    const date = moment(`${month} ${day} ${year}`).format('YYYY-MM-DD')

    return date
}

// Used to book appointments in Office Line
// 2000-05-01 12:00:00 (YYYY-MM-DD HH:MM:SS)
export const formatOfficeLineDateTime = (dateTime) => {
    const date = moment(dateTime).locale('en-gb').format('YYYY-MM-DD HH:mm:ss')

    return date
}

// Used in advertorials
// 25 January, 2022 (two days previous)
export const twoDaysAgo = () => {
    const twoDaysAgo = moment().subtract(2, 'days').format('LL')

    return twoDaysAgo
}

export const generateMonth = (date) => {
    const month = moment(date).format('MMMM')

    return month
}
